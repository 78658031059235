import React from 'react'
/** @jsx jsx */
import { Container, jsx, Styled, Text } from 'theme-ui'
import { mediaQueries } from '../gatsby-plugin-theme-ui/media-queries'

export const Footer = props => {
  return (
    <footer
      sx={{
        bg: 'iceberg',
        variant: 'footer.primary',
        py: [5, 5, 5, 6],
        px: [5, 5, 5, 6],
        bottom: 0,
        width: '100%',
      }}
    >
      <Container
        variant="layout.container.large"
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <p
          sx={{
            fontSize: [4, 4, 4, 5, 5],
            fontWeight: 'bold',
            m: 0,
            lineHeight: 1,
            textAlign: 'center',
            color: 'inherit',
          }}
        >
          Let’s work together.
        </p>
        <p
          sx={{
            fontSize: [4, 4, 4, 5, 5],
            fontWeight: 'bold',
            m: 0,
            lineHeight: 1,
            textAlign: 'center',
            mb: 5,
            color: 'inherit',
          }}
        >
          vince@underscorelabs.co
        </p>
        <div
          sx={{
            fontWeight: '600',
            fontSize: 4,
            textAlign: `center`,
            lineHeight: 1,
          }}
        >
          © {new Date().getFullYear()}, Built with{' '}
          <a href="https://www.sanity.io" sx={{ variant: `anchor.plain` }}>
            Sanity
          </a>{' '}
          &amp;{' '}
          <a href="https://www.gatsbyjs.org" sx={{ variant: `anchor.plain` }}>
            Gatsby
          </a>
        </div>
      </Container>
    </footer>
  )
}
